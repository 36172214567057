//main colors
$neo_blue: #0052BB;
$neo_orangePale:#de8900;
//extras
$neo_blueDark: #003271;
$neo_bluePale:#1479fb; 
$neo_orange:#ff9e00;
$neo_orangeDark:#ac6b00;

$neo_gray:#898989;
$neo_white:#F7F8FA;
$logo_Big:'../img/brand/neolegal_big.png';

$mini_sidebar:calc(100% - 90px);
$content-margin:70px;
$mini_width:70px;
// .login{

// }
.sweet-loading{
 position:fixed;
 width: 100vw;
 height: 100vh;
 background: rgba(60,60,60,0.4);
 top:0;
 z-index: 9999999;
 >div{
    text-align: center;
     position: relative;
     top:calc(50% - 75px);
     vertical-align: middle;
 }
}


#calendarContainer{
    [role=application]{
        margin:0 auto;
    }
} 

.badge-primary.a:hover, .badge-primary.a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #324cdd; }

.help-block{
    color:$neo_gray;
    font-size: 10px;
    margin-bottom:0px;
}

textarea.form-control{
    transition: 0s !important;
    -webkit-transition: 0s !important; 
}
.high-z-index{
    z-index:2345678
}
.giga-max-width{
    max-width:10000px;
}

.weirdScroll{
    height: calc(100vh - 70px);
}

.lobster{
    font-family: 'Lobster Two', arial;
}

.no-scroll{
    // overflow-y: hidden;
}

.error-list{
    text-align: center;
    h5{
      color:$neo_orange;  
    }
    span{
        padding-bottom:15px;
    }
    pre{
        padding: 0;
    }
}

*{
    &:not(.overflow-x-allow,.fa){
        &::-webkit-scrollbar{
            display: none !important; 
        }
        
        // overflow-x: hidden; 
    }
}

.overflow-x-stimulator{
    text-align: center;
    width:110%;
    max-width: 110%;
    overflow: scroll;
}

// body::-webkit-scrollbar{
//     // display: block !important; 
//     // overflow-x: hidden;
// }

body{
    font-family: 'Ubuntu', sans-serif;
    max-height: $mini_sidebar;
    // height:100vh;
    // overflow: hidden;
    &.bg-default{
        background:$neo_white!important;
    }
}

#root{
    .main-content{
        max-height: $mini_sidebar;
        // overflow: hidden;
    }
}

// .overflow{
//     overflow-y: scroll;
// }

.capitalize::first-letter {
    text-transform: uppercase;
}

//Feeback tag
.invalid-feedback{
    text-align: center;
    padding: 10px;
}

#lang-switch{
    background-color: $neo_white !important;
    color:$neo_blue;
}

.bg-gradient-info{
    background: linear-gradient(87deg, $neo_bluePale 0, $neo_blue 70%, $neo_blueDark 100%) !important; 
    // height:  100vh;;
}


.fill-default{
    fill:$neo_white;
}

.main-content{
    &.login,&.error{
        overflow:hidden;
    }

}

.top-right{
    position:fixed;
    top:10px;
    right:10px;
}

.philosopher{
    float: right;
    font-family: serif;
}

.nav-link{
    cursor: pointer;
}

#navbar-main{
    background: $neo_blue;
    position: initial;
    padding:0;
    .container-fluid{
        width:$mini_sidebar;
        margin:5px 15px !important;
        padding:0!important;
    }
}

label{
    font-size: 12px;
    margin-bottom:2px;
}

.form-group{
    margin-bottom: 0px !important;
}

.coolHandle{
    width:10px;
    height:10px;
    display:block;
    background: $neo_blueDark;
}

fieldset{
    margin-bottom: 15px !important;
}

@media (min-width: 768px){
    // .navbar-vertical.navbar-expand-md .navbar-nav .nav-link{
    //     padding:initial;
    // }
    
    .mini.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
        margin-left: $mini_width; 
    }

    nav#navbar-main{
        display:block !important;
    }

    nav#sidenav-main{
        padding:15px;
        &.mini{
            width:auto;
            // max-width:$mini_width !important;
            // overflow-x: hidden;       
            display:block !important;
        }
    }

}

// if under 768 then
nav#navbar-main{
    display:none;
}

.main-content{
    .container{
        margin-top: $content-margin;
    }
}

nav#navbar-main{
    margin-left: 70px;
    position:fixed;
}

// .letter-format .react-pdf__Page{
//     // padding-top: 129.41%;
//     width: 100%;
//     canvas{
//         margin: auto;
//     }
// }

.legal-format .react-pdf__Page{
    // padding-top: 164.71%;
    width: 100% !important;
    canvas{
        margin: auto;
    }
}

.pdf-overflow-control{
    margin-top: 15px;
    // overflow: hidden;
    text-align: center;
}

.form-height{
    max-height: calc(100vh - 70px);
    // overflow: scroll;
}

#PDF-area{
    overflow: scroll !important;
    padding:5px;
    background: $neo_gray;
    text-align: center;
    canvas{
        margin: 0 auto;
        padding:5px;
    }
    &.m-auto{
        width: calc( ( 100vw / 12 * 7 ) - 70px);
        max-width: calc( ( 100vw / 12 * 7 ) - 70px);
        max-height: calc(100vh - 70px);
    }
}

.react-pdf__Page{
    text-align: center;
}

.content-top{
    // position: relative;
    position: fixed;
    top:85px;
    right:15px;;

}

.pdf-options{
    position:absolute;
    top:5px;
    right:21px;
    text-align: center;
    z-index: 999;
    // button{
    //     // display: flex;
    //     // align-items: center;
    // }
}

#PDF-preview{
    text-align:right;
}

.display-none{
    display:none;
}

.text-align-center{
    text-align: center;
}

.navbar{
    width:auto;
}

.re-init{
    position: sticky;
    top:0;
    width:100%;
}

.avatar{
    background-color: transparent;
}

.form-padding{
    padding:15px 0;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
    #PDF-area{
        &.m-auto{
            width:auto;
            max-width:none;
            max-height: none;
            display:block;
            position: initial
        }
    }

    .form-height{
        max-height: none;
    }

    .weirdScroll{
        height:auto;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    
}

form .radio{
    display:inline-block;
    span span{
        vertical-align: text-bottom;
        margin-right:5px;
    }
}

form .checkbox{
    span{
        vertical-align: text-bottom;
    }
}

.middle-fixed{
    position:fixed;
    z-index:99999;
    left:calc(50% - 127px)
}

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    input { 
        opacity: 0;
        width: 0;
        height: 0;

        
        &:checked + .slider {
            background-color: #2196F3;
        }
        
        &:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }
        
        &:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
        
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    
        /* Rounded sliders */
        &.round {
            border-radius: 34px;
        }
        
        &.round:before {
            border-radius: 50%;
        }
          
      }
  }
  
    
  

  
  