body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#signature-pad {
  border: 2px dashed;
  border-color:#cfcfcf;
}

/* 8. Menu styles */
/*
  STYLE NAVIGATION
  
#1. Logged User Box
#2. Shared styles between Top Bar & Main Menu
#3. Desktop Menu
#4. Mobile Menu

*/
/* 
#1. Logged User Box
*/
.logged-user-w {
  text-align: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.logged-user-w .avatar-w {
  padding: 5px;
  border: 1px solid #262626;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.logged-user-w .avatar-w img {
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.logged-user-w .logged-user-role {
  display: block;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.logged-user-w .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline {
  text-align: left;
  position: relative;
}

.logged-user-w.avatar-inline .logged-user-i {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 0.7rem;
}

.logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 20px;
  text-align: left;
}

.logged-user-w.avatar-inline .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline .logged-user-avatar-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.logged-user-w.avatar-inline .logged-user-avatar-info .logged-user-info-w {
  margin-bottom: 0px;
  padding: 10px 10px 10px 20px;
}

/*
#2. Shared styles between Top Bar & Main Menu
*/
.menu-w .logo-w,
.top-bar .logo-w {
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.menu-w .logo-w .filters-toggler,
.top-bar .logo-w .filters-toggler {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.menu-w .logo-w .filters-toggler .os-icon,
.top-bar .logo-w .filters-toggler .os-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.menu-w .logo-w .filters-toggler:hover,
.top-bar .logo-w .filters-toggler:hover {
  color: #fff;
}

.menu-w .logo-w .logo,
.top-bar .logo-w .logo {
  display: inline-block;
  text-decoration: none;
}

.menu-w .logo-w .logo-element,
.top-bar .logo-w .logo-element {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 15px;
  position: relative;
  background-color: #98c9fd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo-element:after,
.top-bar .logo-w .logo-element:after {
  content: "";
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 15px;
  right: -20px;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo:hover .logo-element,
.top-bar .logo-w .logo:hover .logo-element {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logo-w .logo:hover .logo-element:after,
.top-bar .logo-w .logo:hover .logo-element:after {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.menu-w .logo-w .logo:hover .logo-label:after,
.top-bar .logo-w .logo:hover .logo-label:after {
  width: 100%;
  background-color: #fff;
}

.menu-w .logo-w .logo-label,
.top-bar .logo-w .logo-label {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.81rem;
  position: relative;
  margin-left: 10px;
}

.menu-w .logo-w .logo-label:after,
.top-bar .logo-w .logo-label:after {
  height: 2px;
  position: absolute;
  width: 0%;
  left: 0px;
  bottom: -5px;
  background-color: #fff;
  content: "";
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/*
  #3. Desktop Menu
*/
.menu-w {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  -webkit-box-flex: 0;
          flex: 0 0 260px;
  border-right: 0px solid rgba(0, 0, 0, 0.05);
  /* Styles for sub menu */
}

.menu-w .menu-page-header {
  display: none;
}

.menu-w .logged-user-w {
  position: relative;
  padding: 1rem;
}

.menu-w .logged-user-w .logged-user-i:hover {
  cursor: pointer;
}

.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.menu-w .logged-user-w + .main-menu {
  padding-top: 0px;
}

.menu-w .logged-user-menu {
  background: #323c58;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -1px;
  left: -10px;
  overflow: hidden;
  padding: 1rem;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 6px;
}

.menu-w .logged-user-menu .avatar-w {
  border-color: #fff;
}

.menu-w .logged-user-menu .logged-user-avatar-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w .logged-user-menu .logged-user-info-w .logged-user-name {
  color: #fff;
}

.menu-w .logged-user-menu .logged-user-info-w .logged-user-role {
  color: rgba(255, 255, 255, 0.6);
}

.menu-w .logged-user-menu ul {
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px 30px;
  padding-bottom: 20px;
}

.menu-w .logged-user-menu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-w .logged-user-menu ul li a {
  display: block;
  padding: 10px;
  color: #fff;
}

.menu-w .logged-user-menu ul li a i {
  vertical-align: middle;
  margin-right: 15px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.menu-w .logged-user-menu ul li a span {
  vertical-align: middle;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.menu-w .logged-user-menu ul li a:hover {
  text-decoration: none;
}

.menu-w .logged-user-menu ul li a:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logged-user-menu ul li a:hover span {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.menu-w .logged-user-menu ul li:last-child {
  border-bottom: none;
}

.menu-w .logged-user-menu .bg-icon {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -40px;
  right: -20px;
}

.menu-w .logged-user-menu.color-style-light {
  background-color: #fff;
}

.menu-w .logged-user-menu.color-style-light .logged-user-avatar-info {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-name {
  color: #334152;
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-role {
  color: #adb5bd;
}

.menu-w .logged-user-menu.color-style-light .avatar-w {
  border-color: #111;
}

.menu-w .logged-user-menu.color-style-light ul li {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w .logged-user-menu.color-style-light ul li a {
  color: #3E4B5B;
}

.menu-w .logged-user-menu.color-style-light ul li a i {
  color: #047bf8;
}

.menu-w .logged-user-menu.color-style-dark {
  background-color: #323c58;
}

.menu-w .logged-user-menu.color-style-bright {
  background-color: #1b55e2;
}

.menu-w .logged-user-menu.color-style-bright-alt {
  background-color: #5e00da;
}

.menu-w .side-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 20px;
}

.menu-w .side-menu-magic h1, .menu-w .side-menu-magic h2, .menu-w .side-menu-magic h3, .menu-w .side-menu-magic h4, .menu-w .side-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-w .side-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-w .side-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-w .side-menu-magic .btn-white, .menu-w .side-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-w .side-menu-magic .fc-button {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-w .logo-w {
  text-align: center;
  padding: 14px 1rem 14px 1.7rem;
}

.menu-w .logo-w a {
  display: inline-block;
}

.menu-w .logo-w .logo {
  display: inline-block;
}

.menu-w .logo-w img {
  width: 40px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.menu-w .logo-w img.l-logo {
  width: 120px;
}

.menu-w .logo-w span {
  vertical-align: middle;
  display: inline-block;
  color: #334152;
  margin-left: 1rem;
}

.menu-w ul.main-menu {
  list-style: none;
  padding: 2rem 1rem;
  margin-bottom: 0px;
}

.menu-w ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-w ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 5px;
}

.menu-w ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-w ul.main-menu > li.sub-header {
  text-transform: uppercase;
  color: #448eef;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 0px;
  border-bottom: none;
}

.menu-w ul.main-menu > li.sub-header:first-child {
  padding-top: 0px;
}

.menu-w ul.main-menu > li.sub-header:first-child span {
  padding-top: 0px;
}

.menu-w ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
  font-size: 0.99rem;
}

.menu-w ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-w ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-w ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  .menu-w ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-w ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem 1rem 1rem 0rem;
  width: 70px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li span {
  padding: 1rem;
  padding-left: 0.8rem;
  display: block;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-w ul.sub-menu {
  padding: 0px;
  padding-left: 55px;
  display: none;
  list-style: none;
}

.menu-w ul.sub-menu:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 1rem;
}

.menu-w ul.sub-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.sub-menu li a {
  padding: 0.4rem 10px 0.4rem 10px;
  display: block;
  position: relative;
  font-size: 0.81rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.sub-menu li a:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #047bf8;
  position: absolute;
  left: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  display: block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.menu-w ul.sub-menu li a:hover {
  text-decoration: none;
  cursor: pointer;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
}

.menu-w ul.sub-menu li a:hover:before {
  -webkit-transform: translate(-5px, -50%);
          transform: translate(-5px, -50%);
  border-radius: 6px;
  background-color: #047bf8;
  border-color: #047bf8;
}

.menu-w.color-scheme-light .messages-notifications,
.menu-w.color-scheme-light .top-icon {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.color-scheme-light .logo-w .logo-label {
  color: #3E4B5B;
}

.menu-w.color-scheme-light .logo-w .logo-element {
  background-color: #0a7cf8;
}

.menu-w.color-scheme-light .logo-w .logo-element:after {
  background-color: #bfd6f9;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label:after {
  background-color: #047bf8;
}

.menu-w.color-scheme-dark {
  background-color: #293145;
  background-image: none;
  color: rgba(255, 255, 255, 0.9);
  border-right-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.menu-w.color-scheme-dark .messages-notifications,
.menu-w.color-scheme-dark .top-icon {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-menu {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
  box-shadow: 0px 5px 10px rgba(19, 19, 19, 0.17);
}

.menu-w.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-w.color-scheme-dark.color-style-bright ul.main-menu .icon-w {
  color: #95acff;
}

.menu-w.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-w.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:hover > .icon-w {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-w.color-scheme-dark .sub-menu-w {
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .logo-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logo-w span {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w {
  background-color: transparent;
  box-shadow: none;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu:first-child {
  border-top-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:before {
  border-color: #fff;
  opacity: 0.7;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover:before {
  background-color: #fff;
  opacity: 1;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-style-transparent {
  background: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border-right-width: 1px;
}

.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#0e369a));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #0e369a 100%);
  background-repeat: repeat-x;
}

.menu-w.sub-menu-style-inside .sub-menu-header {
  display: none;
}

.menu-w.sub-menu-style-inside .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-inside ul.sub-menu {
  padding-left: 45px;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child {
  padding-bottom: 1rem;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a:before, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a:before {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a span, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a span {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a .icon-w, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu {
  position: relative;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu > a:before {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active > a:before {
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-w {
  position: absolute;
  background-color: #fff;
  padding: 10px 20px 20px;
  border-radius: 6px;
  right: 0px;
  top: 50%;
  -webkit-transform: translate(110%, -50%);
          transform: translate(110%, -50%);
  z-index: 10000;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-header {
  font-size: 1.35rem;
  font-weight: 500;
  color: #047bf8;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-w.sub-menu-style-flyout .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-flyout .sub-menu-i {
  display: -webkit-box;
  display: flex;
}

.menu-w.sub-menu-style-flyout ul.sub-menu {
  display: block;
  padding-left: 0px;
  border: none;
  -webkit-box-flex: 0;
          flex: 0 0 180px;
  padding: 0px 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li {
  min-width: 200px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a {
  color: #3E4B5B;
  white-space: nowrap;
  font-size: 0.9rem;
  padding: 8px 12px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:before {
  opacity: 0;
  -webkit-transform: translate(5px, -50%);
          transform: translate(5px, -50%);
  background-color: #047bf8;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover {
  color: #047bf8;
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover:before {
  opacity: 1;
  -webkit-transform: translate(12px, -50%);
          transform: translate(12px, -50%);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li .badge {
  font-size: 0.72rem;
  padding: 2px 5px;
  margin-left: 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu + ul.sub-menu {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #323c58;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w .sub-menu-header, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:before, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:before {
  background-color: #fff;
  border-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:hover, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu + ul.sub-menu, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li {
  position: relative;
}

.menu-w.sub-menu-style-over ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover {
  border-right-color: #1b55e2;
  border-bottom-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a {
  background-color: #1b55e2;
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a:before {
  color: #fff;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px) scale(1);
          transform: translateX(0px) scale(1);
}

.menu-w.sub-menu-style-over .sub-menu-w {
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(40px) scale(0.95);
          transform: translateX(40px) scale(0.95);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  background-color: #1b55e2;
  color: #fff;
  left: 100%;
  top: -100px;
  overflow: hidden;
  text-align: left;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-header {
  font-size: 2.4rem;
  color: rgba(255, 255, 255, 0.2);
  padding: 5px 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-icon {
  position: absolute;
  font-size: 120px;
  color: rgba(255, 255, 255, 0.1);
  bottom: -50px;
  right: -10px;
  display: block;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w ul.sub-menu:first-child {
  border-top: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul {
  list-style: none;
  padding: 0px;
  min-width: 250px;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li:last-child, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a {
  color: #fff;
  display: block;
  padding: 12px 50px;
  font-size: 0.99rem;
  position: relative;
  white-space: nowrap;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #FBB463;
  border-color: #FBB463;
  position: absolute;
  left: 28px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
          transform: translate(-10px, -50%);
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:hover:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:hover:before {
  opacity: 1;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-header {
  color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left-color: rgba(0, 0, 0, 0.04);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-icon {
  color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover {
  border-right-color: #fff;
  border-bottom-color: #fff;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover > a {
  background-color: #fff;
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover > a .icon-w {
  color: #047bf8;
}

.menu-w.sub-menu-style-over.sub-menu-color-light.color-scheme-light ul.main-menu > li:hover > a:before {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li:hover {
  border-right-color: #323c58;
  border-bottom-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li:hover > a {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li:hover > a {
  background-color: #1b55e2;
}

.menu-w.menu-layout-mini {
  -webkit-box-flex: 0;
          flex: 0 0 70px;
}

.menu-w.menu-layout-mini .logged-user-toggler-arrow {
  display: none;
}

.menu-w.menu-layout-mini .logo-w {
  padding: 17px 15px;
  text-align: center;
}

.menu-w.menu-layout-mini .logo-w .logo-label {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li span {
  display: none;
}

.menu-w.menu-layout-mini .side-menu-magic {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li .icon-w {
  padding: 18px 5px;
  font-size: 30px;
  width: auto;
}

.menu-w.menu-layout-mini ul.main-menu {
  padding: 20px 0px;
}

.menu-w.menu-layout-mini ul.main-menu > li > a {
  -webkit-box-pack: center;
          justify-content: center;
}

.menu-w.menu-layout-mini .main-menu > li.has-sub-menu > a:before {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li.sub-header {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i > .logged-user-info-w {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i {
  padding-left: 0px;
}

.menu-w.menu-layout-mini .logged-user-w {
  text-align: center;
}

.menu-w.menu-layout-mini .logged-user-w {
  padding: 15px;
}

.menu-w.menu-layout-mini .logged-user-menu {
  width: 280px;
}

.menu-w.menu-layout-mini .logged-user-w .logged-user-i > .avatar-w img {
  max-width: 100%;
  height: auto;
}

.menu-w.menu-layout-compact {
  -webkit-box-flex: 0;
          flex: 0 0 260px;
}

.menu-w.menu-layout-compact .logged-user-w .avatar-w {
  padding: 4px;
}

.menu-w.menu-layout-compact .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 15px;
}

.menu-w.menu-layout-compact ul.main-menu {
  padding: 1rem 1.5rem;
  margin-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li {
  border-bottom: none;
}

.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  content: "\e91c";
  font-size: 7px;
  right: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li.sub-header {
  text-transform: uppercase;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li > a {
  font-size: 0.9rem;
}

.menu-w.menu-layout-compact ul.main-menu > li > a:focus {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-w.menu-layout-compact ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  .menu-w.menu-layout-compact ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  font-size: 18px;
  padding: 7px 10px 7px 0px;
  width: 40px;
}

.menu-w.menu-layout-compact ul.main-menu > li span {
  padding: 7px;
  padding-left: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu {
  padding-left: 30px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu:first-child {
  padding-top: 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li {
  border-bottom: none;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a {
  padding: 4px 0px 4px 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a .badge {
  font-size: 0.72rem;
  padding: 2px 3px;
  border-radius: 2px;
  margin-left: 3px;
}

.menu-position-side.menu-side-right .top-bar {
  border-radius: 6px 0px 0px 0px;
}

.menu-position-side.menu-side-right .content-w {
  border-radius: 6px 0px 0px 6px;
}

.menu-position-side.menu-w {
  border-radius: 0px 0px 0px 0px;
}

.menu-position-side.menu-w.menu-side-left .logo-w:first-child {
  border-radius: 0px 0px 0px 0px;
}

.menu-position-side.menu-w.menu-side-right {
  border-radius: 0px 6px 6px 0px;
}

.menu-position-side.menu-w.menu-side-right .logo-w:first-child {
  border-radius: 0px 6px 0px 0px;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w, .menu-position-side.menu-w.menu-side-right.sub-menu-style-over .sub-menu-w {
  left: auto;
  right: 100%;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w {
  -webkit-transform: translate(-10%, -50%);
          transform: translate(-10%, -50%);
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .main-menu > li.has-sub-menu:hover .sub-menu-w {
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent.color-scheme-dark {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
          order: 3;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions .messages-notifications, .menu-position-side.menu-w .menu-actions .top-icon {
  margin: 5px 0px;
}

.menu-position-side.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
}

.menu-position-side.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.menu-position-side.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-side.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
}

.menu-position-side.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-side.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-side.menu-w .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-side.menu-w .element-search input {
  border: none;
  box-shadow: none;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  background-color: rgba(91, 109, 136, 0.1);
}

.menu-position-side.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w.menu-layout-full .menu-actions {
  -webkit-box-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search,
.menu-position-side.menu-w.menu-layout-full .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  -webkit-box-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search,
.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu {
  padding-top: 20px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-mini {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.menu-position-side.menu-w.menu-layout-mini .logo-w {
  -webkit-box-pack: center;
          justify-content: center;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-side.menu-w.menu-layout-mini .element-search {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 10px 0px;
  margin: 0px;
  -webkit-box-ordinal-group: 3;
          order: 2;
}

.menu-position-side.menu-w.menu-layout-mini .element-search:before {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  font-size: 22px;
  color: #fff;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input {
  opacity: 0;
  background-color: transparent;
  width: 100%;
  color: transparent;
  cursor: pointer;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-webkit-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-moz-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input:-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  margin: 0px 10px;
  border-radius: 50px;
  border: none;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions + .element-search {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions {
  background-color: rgba(0, 0, 0, 0.03);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions + .element-search {
  border-top-color: rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .element-search:before {
  color: #047bf8;
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-dark .menu-actions {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-position-side.menu-w.color-scheme-dark .logo-w {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu-position-side.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-light.menu-layout-full .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-full .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-mini .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-mini .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-compact .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-compact .menu-actions {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
  border-right: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w .logged-user-toggler-arrow {
  display: none;
}

.menu-position-top.menu-w.menu-has-selected-link {
  margin-bottom: 50px;
}

.menu-position-top.menu-w ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.menu-position-top.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
          order: 3;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
  -webkit-box-ordinal-group: 4;
          order: 3;
}

.menu-position-top.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.menu-position-top.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-top.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
  -webkit-box-ordinal-group: 3;
          order: 2;
}

.menu-position-top.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-top.menu-w ul.main-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  margin-right: auto;
}

.menu-position-top.menu-w ul.main-menu > li {
  border-bottom: none;
}

.menu-position-top.menu-w .logged-user-w {
  -webkit-box-ordinal-group: 5;
          order: 4;
  border-bottom: none;
  padding: 5px 15px;
}

.menu-position-top.menu-w .logged-user-w .avatar-w {
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu {
  left: auto;
  right: 0;
  top: -10px;
  width: auto;
  min-width: 230px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul {
  padding: 0px 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul a {
  white-space: nowrap;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info .logged-user-info-w {
  padding: 5px 5px 5px 20px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.menu-position-top.menu-w .logo-w {
  border-bottom: none;
}

.menu-position-top.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-top.menu-w .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-top.menu-w .element-search input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
}

.menu-position-top.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .top-icon,
.menu-position-top.menu-w.color-scheme-dark .messages-notifications {
  color: rgba(255, 255, 255, 0.8);
}

.menu-position-top.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c4cc3));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c4cc3 100%);
  background-repeat: repeat-x;
  box-shadow: none;
}

.menu-position-top.menu-w.color-style-transparent {
  border-bottom-width: 1px;
}

.menu-position-top.menu-w.color-scheme-light .element-search input {
  background-color: rgba(121, 138, 185, 0.07);
}

.menu-position-top.menu-w.menu-layout-mini .logo-w {
  padding: 10px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a .icon-w {
  padding: 0px;
  font-size: 28px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full > .logged-user-w > .logged-user-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active span {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.menu-layout-full .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full .logo-w {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  margin-right: 20px;
  padding: 10px 20px;
}

.menu-position-top.menu-w.menu-layout-full .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a .icon-w {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a span {
  font-size: 0.81rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 2px;
  padding: 0px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a {
  padding-right: 30px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a:before {
  right: 13px;
  font-size: 7px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w {
  border: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .logged-user-info-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .avatar-w {
  vertical-align: middle;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w {
  padding: 10px;
  padding-right: 20px;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu {
  padding: 0px 10px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a {
  padding-right: 25px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  right: 15px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .sub-menu-w .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a {
  padding: 8px 12px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover span {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.active a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  width: auto;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li span {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact.sub-menu-style-flyout ul.main-menu > li .sub-menu-w, .menu-position-top.menu-w.menu-layout-compact.sub-menu-style-over ul.main-menu > li .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-compact.color-scheme-dark ul.main-menu > li, .menu-position-top.menu-w.menu-layout-compact.color-scheme-bright ul.main-menu > li {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-position-top.menu-w ul.main-menu > li.selected {
  position: static;
  background-color: #fff;
}

.menu-position-top.menu-w ul.main-menu > li.selected .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w {
  -webkit-transform: none !important;
          transform: none !important;
  border-radius: 0px !important;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px !important;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-header,
.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-icon {
  display: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  min-width: auto;
  border: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
  min-width: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 15px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected {
  border-right-color: rgba(0, 0, 0, 0.05) !important;
  background-color: transparent !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a {
  background-color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a .icon-w {
  color: #047bf8 !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light.color-scheme-light .sub-menu-w {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a {
  background-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w {
  background-color: #323c58;
  box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w {
  background-color: #1b55e2;
  box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over .sub-menu-w {
  left: 0px;
  top: 100%;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active {
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li.active {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li.active {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 15px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active {
  background-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active {
  background-color: #fff;
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active {
  background-color: #323c58;
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active {
  background-color: #1b55e2;
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  -webkit-transform: translate(50%, 0);
          transform: translate(50%, 0);
}

.menu-position-top.menu-w.sub-menu-style-flyout .sub-menu-w {
  right: 50%;
  top: 100%;
  -webkit-transform: translate(50%, 20%);
          transform: translate(50%, 20%);
}

.menu-position-top.menu-w.menu-with-image {
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  flex-wrap: wrap;
  padding-top: 5px;
  border-bottom: none;
  box-shadow: none;
}

.menu-position-top.menu-w.menu-with-image .top-icon > .os-icon,
.menu-position-top.menu-w.menu-with-image .messages-notifications > .os-icon {
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.menu-position-top.menu-w.menu-with-image > .element-search {
  margin-left: auto;
}

.menu-position-top.menu-w.menu-with-image .logo-w {
  padding-left: 20px;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w {
  -webkit-box-ordinal-group: 5;
          order: 4;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w > .logged-user-i > .avatar-w {
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 4px;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu {
  -webkit-box-ordinal-group: 7;
          order: 6;
  margin-top: 20px;
  -webkit-box-flex: 0;
          flex: 0 0 100%;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image .menu-page-header {
  display: block;
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  padding: 30px 20px;
  margin-bottom: 0px;
  -webkit-box-ordinal-group: 6;
          order: 5;
  margin-top: 30px;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.menu-position-top.menu-w.menu-with-image h1.menu-page-header {
  font-size: 3.25rem;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light .top-icon > .os-icon,
.menu-position-top.menu-w.menu-with-image.color-scheme-light .messages-notifications > .os-icon {
  color: #047bf8;
  text-shadow: none;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light .logo-w .logo-label {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light ul.main-menu .icon-w {
  color: #047bf8;
}

/*
  #4. Mobile Menu
*/
.menu-mobile {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  position: relative;
  display: none;
  /* Styles for sub menu */
}

.menu-mobile .mm-logo-buttons-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo {
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-logo img {
  width: 40px;
  display: inline-block;
}

.menu-mobile .mm-logo-buttons-w .mm-logo span {
  display: inline-block;
  color: #3E4B5B;
  margin-left: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo:hover {
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-buttons {
  display: -webkit-box;
  display: flex;
}

.menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  color: #047bf8;
}

.menu-mobile .mm-logo-buttons-w .mobile-menu-trigger {
  vertical-align: middle;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
  color: #047bf8;
}

.menu-mobile .menu-and-user {
  display: none;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-mobile .logged-user-w {
  text-align: left;
  padding: 1rem;
  padding-left: 35px;
}

.menu-mobile .logged-user-w .avatar-w {
  vertical-align: middle;
}

.menu-mobile .logged-user-w .avatar-w img {
  width: 40px;
}

.menu-mobile .logged-user-w .logged-user-info-w {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.menu-mobile .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 10px 20px;
}

.menu-mobile .mobile-menu-magic:last-child {
  margin-bottom: 0px;
}

.menu-mobile .mobile-menu-magic h1, .menu-mobile .mobile-menu-magic h2, .menu-mobile .mobile-menu-magic h3, .menu-mobile .mobile-menu-magic h4, .menu-mobile .mobile-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-mobile .mobile-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-mobile .mobile-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-mobile .mobile-menu-magic .btn-white, .menu-mobile .mobile-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-mobile .mobile-menu-magic .fc-button {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-mobile ul.main-menu {
  list-style: none;
  padding: 10px 1rem;
  margin-bottom: 0px;
}

.menu-mobile ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-mobile ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.menu-mobile ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-mobile ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}

.menu-mobile ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-mobile ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-mobile ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  .menu-mobile ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-mobile ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem;
  width: 80px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li span {
  padding: 1rem;
  display: block;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-mobile ul.sub-menu {
  padding: 1rem 0px;
  padding-left: 55px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: none;
}

.menu-mobile ul.sub-menu li {
  padding: 0.4rem 10px 0.4rem 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-mobile ul.sub-menu li a {
  font-size: 0.81rem;
}

.menu-mobile.color-scheme-dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3D4D75), to(#31395B));
  background-image: linear-gradient(to bottom, #3D4D75 0%, #31395B 100%);
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c2e7b));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
  background-repeat: repeat-x;
  color: rgba(255, 255, 255, 0.9);
}

.menu-mobile.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

.menu-mobile.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-mobile.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-mobile.color-scheme-dark .sub-menu-w {
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w span {
  color: #fff;
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open,
.menu-mobile.color-scheme-dark .mm-logo-buttons-w .mobile-menu-trigger {
  color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-mobile.color-scheme-dark .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

.sidebarMargin{
  position:relative;
  padding-left: 30px !important;
  left:50px;
  width:calc(100% - 80px) !important;
}