.min-height-800:{
  min-height:800px;
}

.pdf-overflow-control{
  margin-top: 15px;
  text-align: center;
}

.form-height{
  max-height: calc(100vh - 70px);
}

#PDF-area{
  overflow: scroll !important;
  padding:5px;
  background: $neo_gray;
  text-align: center;

}

#PDF-area canvas{
  margin: 0 auto;
  padding:5px;
}
#PDF-area .m-auto{
  width: calc( ( 100vw / 12 * 7 ) - 70px);
  max-width: calc( ( 100vw / 12 * 7 ) - 70px);
  max-height: calc(100vh - 70px);
}

.react-pdf__Page{
  text-align: center;
}

.content-top{
  position: fixed;
  top:85px;
  right:15px;;

}

.pdf-options{
  position:absolute;
  top:5px;
  right:21px;
  text-align: center;
  z-index: 999;

}

#PDF-preview{
  text-align:right;
}


/* .table{
  overflow: scroll;
  display: block;
} */

.neo-nav{
  border-bottom: 2px solid #5170fa;
  /* background-color: #0051bb;
  border-radius: 6px;
  color: #fff !important; */
}

.neo-card{
  border-left: 2px solid #0052BB !important;
}


.cardheader{
  background-color: #efefff !important;
  color: #0052BB;
  /* border-left: 2px solid #0052BB !important; */
  padding: 15px !important;
}


.cardbody{
  /* border-left: 2px solid #0052BB !important; */
  padding: 1rem !important;
}
canvas{
  border: 2px dashed #dfdfdf;
}
.blueTransform{
  transition-duration: 1s !important
}

.blueTransform  .card-title{
  margin-bottom: 0 !important;
}

 .blueTransform:hover{
  cursor: pointer !important;
  /* background-color: #0052BB !important; */
  /* color:#fff !important; */
} 

#neoForm,#products{
  padding: 15px;;
}

#neoForm label{
  font-weight: bold;
}

.centerCalendar{
  text-align: center !important;
  margin: 0 auto;
  max-height: 100vh;
  overflow-y: scroll;
}

#rawr{color:red !important}

.lead-board #booking-app{
  padding: 5px;
}

.lead-board{
  /* background-color: lavender; */
  max-height: 100vh;
  overflow-y: scroll;
  color: initial !important;
}

#booking-app{
  background:rgb(250,250,250);
  /* background: 
  repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgb(205, 235, 255) 10px,
    rgb(205, 235, 255) 20px
  ),
  linear-gradient(
    to bottom,
    rgb(195, 235, 255),
    rgb(195, 235, 255)
  ); */
}
div[class$=-menu]{
  z-index: 99999999 !important;
}

option{
  min-width: 370px;
}

.tui-full-calendar-layout .tui-full-calendar-content hr{
  margin: 4px;
}
.tui-full-calendar-time-schedule-content-time{
  text-align: center;
}

.ticket-link{
  width:100%;
  background: #fefefe;
  text-align: center;
  display: inline-block;
  transition: 2s;
  border: 2px dashed #cdcdcd;
}

.ticket-link:hover{ 
  background:#eee;
  /* filter:grayscale(75%); */
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{
  max-width:10000000px !important;
}

.dropdown,
.dropdown-toggle,
.dropdown-menu {
  /* width: 100%; */
  text-align: left
}

.card{
  margin:5px;
}

.date-mark{
  background:#000;
  color:#fff;
}

.calendar-btn{
  float: left;
  /* margin: 2px; */
}

.alert{
  width:calc(100% - 5px);
  font-size:11px !important;
}

.booking-delete{
  padding: 1px 4px !important;
  position: absolute !important;
  top: -9px !important;
  right: -6px !important;
  font-size: 9px !important;
}

.calendar-btn button{
  margin: 2px;
  font-size: 12px;
  transition: none;
  -webkit-transition: none;
  display:block;
  /* width:48px; */
  padding:2px 4px;
}

.calendar{
  margin: 0;
  display: inherit;
}

.btn{
  margin:2px !important;
}

.btn-faef3d{
  background:#faef3d;
  color:#FFF;
}

.btn-000{
  background:#000000;
  color:#FFF;
}

.btn-000000{
  background:#000000;
  color:#FFF !important;
}

.btn-000:hover{
  background:#666;
  color:#FFF;
}

.btn-000000:hover{
  background:#666;
  color:#FFF !important;
}
.btn-neo-orange{
  background:#de8900;
  color:#FFF;
}

#atlwdg-trigger{
  /* bottom:20px; */
  z-index:20;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
 /* background-color: red !important; */
}


#atlwdg-trigger:after{
 content: "";
 position: absolute;
 bottom:-96px; right: -96px;
 /* opacity:0.4; */
 background-color:inherit;
 padding-bottom:50%; width:57.7%;
 z-index:-1;
 border-top:#6781C2 1px solid;
 
 -webkit-transform-origin:0 0;
 -ms-transform-origin:0 0;
 transform-origin:0 0;
 background:#13ABCF;
 width:210px;
 height: 44px;;
 -webkit-transform: rotate(-45deg);
 -ms-transform: rotate(-45deg);
 transform: rotate(-45deg);
}

#atlwdg-trigger,#atlwdg-trigger:after:hover{
  transition:1s;
  
 background:#01cffd !important;
}
 
.btn-neo-orange:hover,
.btn-neo-blue:hover{
  filter:grayscale(25%);
  color:white
}

.button-select{
  width:320px;
  min-width:320px;
}

.add-button{
  max-width:20%;
  min-width:20%;
  flex-basis:75px;}

.btn-neo-blue{
  background:#0052BB !important;
  color:#FFF;
}

.btn-E34F32{
  color:#FFF;
  background-color: #E34F32 !important;
}
.btn-E34F32:hover{
  color:#FFF;
}

.DayPicker__hidden {
  height: 100%;
  visibility: visible;

}

.openTicket{
  background:#E34F32;
}

.bold{
  font-weight: bolder;
}

.badge-block{
  display:block;
  margin:2px;
  padding-top:4px;
  padding-bottom:4px;
}
/* .SingleDatePicker{

} */

.duration{
  border-color:#ced4da;
}

*::-webkit-scrollbar { width: 0 !important }

.chosen{
  background-color: #994545;
}

.calendarItem{
  flex-basis:0;
  flex-grow:1;
  text-align:center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.timeRow div{
  
  border-bottom: 1px dashed #bfbfbf;
  padding: 30px 0 30px 0;
}

#calendar td>div>div{
  padding: 30px 0 30px 0;
  border-bottom: 1px dashed #bfbfbf;
  background:#cdcdcd;
  /* in case of bad design thirst */
  /* background:#343434 url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/2999b23d-70d7-4717-bbec-5feb6fb1a768/d4mlyrf-dfab4454-ad55-4860-8330-851b32f49da8.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI5OTliMjNkLTcwZDctNDcxNy1iYmVjLTVmZWI2ZmIxYTc2OFwvZDRtbHlyZi1kZmFiNDQ1NC1hZDU1LTQ4NjAtODMzMC04NTFiMzJmNDlkYTguZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.ARzh6N0nTscACJjUaiE6rIdRugwHV494rEKzvrf0qyE') */
}

#calendar.lawyer td>div>div{
  padding: 0;
  border-bottom: 1px dashed #bfbfbf;
  background:#cdcdcd;
  /* in case of bad design thirst */
  /* background:#343434 url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/2999b23d-70d7-4717-bbec-5feb6fb1a768/d4mlyrf-dfab4454-ad55-4860-8330-851b32f49da8.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzI5OTliMjNkLTcwZDctNDcxNy1iYmVjLTVmZWI2ZmIxYTc2OFwvZDRtbHlyZi1kZmFiNDQ1NC1hZDU1LTQ4NjAtODMzMC04NTFiMzJmNDlkYTguZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.ARzh6N0nTscACJjUaiE6rIdRugwHV494rEKzvrf0qyE') */
}

#calendar.lawyer  .time{
  vertical-align: text-top; 
}

#calendar.lawyer  .time:first-child{
  border-top: 1px dashed #bfbfbf;
}

.calendarItem:hover{
  filter:grayscale(50%)
}

#lawyerList{
  width:160px;
  height: 40px !important;
  display: inline-block;
}

#lawyerList > #addLawyer{
  height: 36px;
  margin-right: 10px;
}

@media (max-width: 300px) {
  .neologo{
    width: 160px !important;
  }
}



#lawyerListDisplay{
  width:calc(100vw - 220px) !important;
  margin:0 !important;
  display: inline-block;
}
#weekSearch,
#filterByType,
#viewType,
#category
{
  padding-top: 2px;
  
}

#filterByType > div,
#weekSearch > div{
  /* height: 38px; */
  padding-top: 2px;
}



#booking{
  /* background: #FEFEFE; */
  padding-bottom: 30px;
}

.form-group{
  padding-bottom: 4px;
}

.list-group{
  margin-top:10px;
}

.alert{
  padding:4px !important;
  text-align: center;
}

.toolTip{
  /* display:none; */
}

.journalier{
  display:block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align:right;
}

.journalierParent{
  padding-left:10px;
  text-align:right;
  width:100%;
  position: relative;
  display: block;
  height: 100%;
}

.calendarItem:hover>.toolTip{
  display:block;
}
.color-1{background:#FFEA85 !important}
.color-2{background:#E87984 !important}
.color-3{background:#92B3FF !important}
.color-4{background:#66EB50 !important}
.color-5{background:#91FFEE !important}
.color-6{background:#7A72E8 !important}
.color-7{background:#FF8ABB !important}
.color-8{background:#FF8ABB !important}
.color-9{background:#FFD499 !important}
.color-10{background:#F6FF7D !important}
.color-11{background:#CA8CFF !important}
.color-12{background:#EEBFBD !important}
.color-13{background:#FFB74A !important}
.color-14{background:#E8E738 !important}
.color-0{background:#3DFF76 !important}

.centerCalendar > *{
  margin: 0 auto !important;
}


/* ##. User Override Styles */
/*# sourceMappingURL=main.css.map */


.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-26 {
  font-size: 26px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-34 {
  font-size: 34px !important; }

.fs-36 {
  font-size: 36px !important; }

.fs-38 {
  font-size: 38px !important; }

.fs-40 {
  font-size: 40px !important; }

@media (max-width: 991px) {
  .sm-fs-10 {
    font-size: 10px !important; }
  .sm-fs-11 {
    font-size: 11px !important; }
  .sm-fs-12 {
    font-size: 12px !important; }
  .sm-fs-13 {
    font-size: 13px !important; }
  .sm-fs-14 {
    font-size: 14px !important; }
  .sm-fs-15 {
    font-size: 15px !important; }
  .sm-fs-16 {
    font-size: 16px !important; }
  .sm-fs-18 {
    font-size: 18px !important; }
  .sm-fs-20 {
    font-size: 20px !important; }
  .sm-fs-22 {
    font-size: 22px !important; }
  .sm-fs-24 {
    font-size: 24px !important; }
  .sm-fs-26 {
    font-size: 26px !important; }
  .sm-fs-28 {
    font-size: 28px !important; }
  .sm-fs-30 {
    font-size: 30px !important; }
  .sm-fs-32 {
    font-size: 32px !important; }
  .sm-fs-34 {
    font-size: 34px !important; }
  .sm-fs-36 {
    font-size: 36px !important; }
  .sm-fs-38 {
    font-size: 38px !important; } }

    /* Generic Padding Helpers
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.padding-0 {
  padding: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.padding-5 {
  padding: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.padding-10 {
  padding: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.padding-15 {
  padding: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.padding-20 {
  padding: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.padding-25 {
  padding: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.padding-30 {
  padding: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.padding-35 {
  padding: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.padding-40 {
  padding: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.padding-45 {
  padding: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.padding-50 {
  padding: 50px !important; }

.p-t-55 {
  padding-top: 55px !important; }

.p-r-55 {
  padding-right: 55px !important; }

.p-l-55 {
  padding-left: 55px !important; }

.p-b-55 {
  padding-bottom: 55px !important; }

.padding-55 {
  padding: 55px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.padding-60 {
  padding: 60px !important; }

.p-t-65 {
  padding-top: 65px !important; }

.p-r-65 {
  padding-right: 65px !important; }

.p-l-65 {
  padding-left: 65px !important; }

.p-b-65 {
  padding-bottom: 65px !important; }

.padding-65 {
  padding: 65px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.padding-70 {
  padding: 70px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.padding-75 {
  padding: 75px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.padding-80 {
  padding: 80px !important; }

.p-t-85 {
  padding-top: 85px !important; }

.p-r-85 {
  padding-right: 85px !important; }

.p-l-85 {
  padding-left: 85px !important; }

.p-b-85 {
  padding-bottom: 85px !important; }

.padding-85 {
  padding: 85px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.padding-90 {
  padding: 90px !important; }

.p-t-95 {
  padding-top: 95px !important; }

.p-r-95 {
  padding-right: 95px !important; }

.p-l-95 {
  padding-left: 95px !important; }

.p-b-95 {
  padding-bottom: 95px !important; }

.padding-95 {
  padding: 95px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.padding-100 {
  padding: 100px !important; }

.p-t-105 {
  padding-top: 105px !important; }

.p-r-105 {
  padding-right: 105px !important; }

.p-l-105 {
  padding-left: 105px !important; }

.p-b-105 {
  padding-bottom: 105px !important; }

.padding-105 {
  padding: 105px !important; }

.p-t-110 {
  padding-top: 110px !important; }

.p-r-110 {
  padding-right: 110px !important; }

.p-l-110 {
  padding-left: 110px !important; }

.p-b-110 {
  padding-bottom: 110px !important; }

.padding-110 {
  padding: 110px !important; }

.p-t-115 {
  padding-top: 115px !important; }

.p-r-115 {
  padding-right: 115px !important; }

.p-l-115 {
  padding-left: 115px !important; }

.p-b-115 {
  padding-bottom: 115px !important; }

.padding-115 {
  padding: 115px !important; }

.p-t-120 {
  padding-top: 120px !important; }

.p-r-120 {
  padding-right: 120px !important; }

.p-l-120 {
  padding-left: 120px !important; }

.p-b-120 {
  padding-bottom: 120px !important; }

.padding-120 {
  padding: 120px !important; }

.p-t-125 {
  padding-top: 125px !important; }

.p-r-125 {
  padding-right: 125px !important; }

.p-l-125 {
  padding-left: 125px !important; }

.p-b-125 {
  padding-bottom: 125px !important; }

.padding-125 {
  padding: 125px !important; }

    /* Generic Margin Helpers
    ------------------------------------
     */
    .m-t-0 {
      margin-top: 0px !important; }

    .m-r-0 {
      margin-right: 0px !important; }

    .m-l-0 {
      margin-left: 0px !important; }

    .m-b-0 {
      margin-bottom: 0px !important; }

    .m-t-5 {
      margin-top: 5px !important; }

    .m-r-5 {
      margin-right: 5px !important; }

    .m-l-5 {
      margin-left: 5px !important; }

    .m-b-5 {
      margin-bottom: 5px !important; }

    .m-t-10 {
      margin-top: 10px !important; }

    .m-r-10 {
      margin-right: 10px !important; }

    .m-l-10 {
      margin-left: 10px !important; }

    .m-b-10 {
      margin-bottom: 10px !important; }

    .m-t-15 {
      margin-top: 15px !important; }

    .m-r-15 {
      margin-right: 15px !important; }

    .m-l-15 {
      margin-left: 15px !important; }

    .m-b-15 {
      margin-bottom: 15px !important; }

    .m-t-20 {
      margin-top: 20px !important; }

    .m-r-20 {
      margin-right: 20px !important; }

    .m-l-20 {
      margin-left: 20px !important; }

    .m-b-20 {
      margin-bottom: 20px !important; }

    .m-t-25 {
      margin-top: 25px !important; }

    .m-r-25 {
      margin-right: 25px !important; }

    .m-l-25 {
      margin-left: 25px !important; }

    .m-b-25 {
      margin-bottom: 25px !important; }

    .m-t-30 {
      margin-top: 30px !important; }

    .m-r-30 {
      margin-right: 30px !important; }

    .m-l-30 {
      margin-left: 30px !important; }

    .m-b-30 {
      margin-bottom: 30px !important; }

    .m-t-35 {
      margin-top: 35px !important; }

    .m-r-35 {
      margin-right: 35px !important; }

    .m-l-35 {
      margin-left: 35px !important; }

    .m-b-35 {
      margin-bottom: 35px !important; }

    .m-t-40 {
      margin-top: 40px !important; }

    .m-r-40 {
      margin-right: 40px !important; }

    .m-l-40 {
      margin-left: 40px !important; }

    .m-b-40 {
      margin-bottom: 40px !important; }

    .m-t-45 {
      margin-top: 45px !important; }

    .m-r-45 {
      margin-right: 45px !important; }

    .m-l-45 {
      margin-left: 45px !important; }

    .m-b-45 {
      margin-bottom: 45px !important; }

    .m-t-50 {
      margin-top: 50px !important; }

    .m-r-50 {
      margin-right: 50px !important; }

    .m-l-50 {
      margin-left: 50px !important; }

    .m-b-50 {
      margin-bottom: 50px !important; }

    .m-t-55 {
      margin-top: 55px !important; }

    .m-r-55 {
      margin-right: 55px !important; }

    .m-l-55 {
      margin-left: 55px !important; }

    .m-b-55 {
      margin-bottom: 55px !important; }

    .m-t-60 {
      margin-top: 60px !important; }

    .m-r-60 {
      margin-right: 60px !important; }

    .m-l-60 {
      margin-left: 60px !important; }

    .m-b-60 {
      margin-bottom: 60px !important; }

    .m-t-65 {
      margin-top: 65px !important; }

    .m-r-65 {
      margin-right: 65px !important; }

    .m-l-65 {
      margin-left: 65px !important; }

    .m-b-65 {
      margin-bottom: 65px !important; }

    .m-t-70 {
      margin-top: 70px !important; }

    .m-r-70 {
      margin-right: 70px !important; }

    .m-l-70 {
      margin-left: 70px !important; }

    .m-b-70 {
      margin-bottom: 70px !important; }

    .m-t-75 {
      margin-top: 75px !important; }

    .m-r-75 {
      margin-right: 75px !important; }

    .m-l-75 {
      margin-left: 75px !important; }

    .m-b-75 {
      margin-bottom: 75px !important; }

    .m-t-80 {
      margin-top: 80px !important; }

    .m-r-80 {
      margin-right: 80px !important; }

    .m-l-80 {
      margin-left: 80px !important; }

    .m-b-80 {
      margin-bottom: 80px !important; }

    .m-t-85 {
      margin-top: 85px !important; }

    .m-r-85 {
      margin-right: 85px !important; }

    .m-l-85 {
      margin-left: 85px !important; }

    .m-b-85 {
      margin-bottom: 85px !important; }

    .m-t-90 {
      margin-top: 90px !important; }

    .m-r-90 {
      margin-right: 90px !important; }

    .m-l-90 {
      margin-left: 90px !important; }

    .m-b-90 {
      margin-bottom: 90px !important; }

    .m-t-95 {
      margin-top: 95px !important; }

    .m-r-95 {
      margin-right: 95px !important; }

    .m-l-95 {
      margin-left: 95px !important; }

    .m-b-95 {
      margin-bottom: 95px !important; }

    .m-t-100 {
      margin-top: 100px !important; }

    .m-r-100 {
      margin-right: 100px !important; }

    .m-l-100 {
      margin-left: 100px !important; }

    .m-b-100 {
      margin-bottom: 100px !important; }

    .m-t-105 {
      margin-top: 105px !important; }

    .m-r-105 {
      margin-right: 105px !important; }

    .m-l-105 {
      margin-left: 105px !important; }

    .m-b-105 {
      margin-bottom: 105px !important; }

    .m-t-110 {
      margin-top: 110px !important; }

    .m-r-110 {
      margin-right: 110px !important; }

    .m-l-110 {
      margin-left: 110px !important; }

    .m-b-110 {
      margin-bottom: 110px !important; }

    .m-t-115 {
      margin-top: 115px !important; }

    .m-r-115 {
      margin-right: 115px !important; }

    .m-l-115 {
      margin-left: 115px !important; }

    .m-b-115 {
      margin-bottom: 115px !important; }

    .m-t-120 {
      margin-top: 120px !important; }

    .m-r-120 {
      margin-right: 120px !important; }

    .m-l-120 {
      margin-left: 120px !important; }

    .m-b-120 {
      margin-bottom: 120px !important; }

    .m-t-125 {
      margin-top: 125px !important; }

    .m-r-125 {
      margin-right: 125px !important; }

    .m-l-125 {
      margin-left: 125px !important; }

    .m-b-125 {
      margin-bottom: 125px !important; }

    .full-height {
      height: 100% !important; }

    .full-width {
      width: 100%; }
      @media (max-width: 767px) {
      .sm-p-t-0 {
        padding-top: 0px !important; }
      .sm-p-r-0 {
        padding-right: 0px !important; }
      .sm-p-l-0 {
        padding-left: 0px !important; }
      .sm-p-b-0 {
        padding-bottom: 0px !important; }
      .sm-p-t-5 {
        padding-top: 5px !important; }
      .sm-p-r-5 {
        padding-right: 5px !important; }
      .sm-p-l-5 {
        padding-left: 5px !important; }
      .sm-p-b-5 {
        padding-bottom: 5px !important; }
      .sm-p-t-10 {
        padding-top: 10px !important; }
      .sm-p-r-10 {
        padding-right: 10px !important; }
      .sm-p-l-10 {
        padding-left: 10px !important; }
      .sm-p-b-10 {
        padding-bottom: 10px !important; }
      .sm-p-t-15 {
        padding-top: 15px !important; }
      .sm-p-r-15 {
        padding-right: 15px !important; }
      .sm-p-l-15 {
        padding-left: 15px !important; }
      .sm-p-b-15 {
        padding-bottom: 15px !important; }
      .sm-p-t-20 {
        padding-top: 20px !important; }
      .sm-p-r-20 {
        padding-right: 20px !important; }
      .sm-p-l-20 {
        padding-left: 20px !important; }
      .sm-p-b-20 {
        padding-bottom: 20px !important; }
      .sm-p-t-25 {
        padding-top: 25px !important; }
      .sm-p-r-25 {
        padding-right: 25px !important; }
      .sm-p-l-25 {
        padding-left: 25px !important; }
      .sm-p-b-25 {
        padding-bottom: 25px !important; }
      .sm-p-t-30 {
        padding-top: 30px !important; }
      .sm-p-r-30 {
        padding-right: 30px !important; }
      .sm-p-l-30 {
        padding-left: 30px !important; }
      .sm-p-b-30 {
        padding-bottom: 30px !important; }
      .sm-p-t-35 {
        padding-top: 35px !important; }
      .sm-p-r-35 {
        padding-right: 35px !important; }
      .sm-p-l-35 {
        padding-left: 35px !important; }
      .sm-p-b-35 {
        padding-bottom: 35px !important; }
      .sm-p-t-40 {
        padding-top: 40px !important; }
      .sm-p-r-40 {
        padding-right: 40px !important; }
      .sm-p-l-40 {
        padding-left: 40px !important; }
      .sm-p-b-40 {
        padding-bottom: 40px !important; }
      .sm-p-t-45 {
        padding-top: 45px !important; }
      .sm-p-r-45 {
        padding-right: 45px !important; }
      .sm-p-l-45 {
        padding-left: 45px !important; }
      .sm-p-b-45 {
        padding-bottom: 45px !important; }
      .sm-p-t-50 {
        padding-top: 50px !important; }
      .sm-p-r-50 {
        padding-right: 50px !important; }
      .sm-p-l-50 {
        padding-left: 50px !important; }
      .sm-p-b-50 {
        padding-bottom: 50px !important; }
      .sm-m-t-0 {
        margin-top: 0px !important; }
      .sm-m-r-0 {
        margin-right: 0px !important; }
      .sm-m-l-0 {
        margin-left: 0px !important; }
      .sm-m-b-0 {
        margin-bottom: 0px !important; }
      .sm-m-t-5 {
        margin-top: 5px !important; }
      .sm-m-r-5 {
        margin-right: 5px !important; }
      .sm-m-l-5 {
        margin-left: 5px !important; }
      .sm-m-b-5 {
        margin-bottom: 5px !important; }
      .sm-m-t-10 {
        margin-top: 10px !important; }
      .sm-m-r-10 {
        margin-right: 10px !important; }
      .sm-m-l-10 {
        margin-left: 10px !important; }
      .sm-m-b-10 {
        margin-bottom: 10px !important; }
      .sm-m-t-15 {
        margin-top: 15px !important; }
      .sm-m-r-15 {
        margin-right: 15px !important; }
      .sm-m-l-15 {
        margin-left: 15px !important; }
      .sm-m-b-15 {
        margin-bottom: 15px !important; }
      .sm-m-t-20 {
        margin-top: 20px !important; }
      .sm-m-r-20 {
        margin-right: 20px !important; }
      .sm-m-l-20 {
        margin-left: 20px !important; }
      .sm-m-b-20 {
        margin-bottom: 20px !important; }
      .sm-m-t-25 {
        margin-top: 25px !important; }
      .sm-m-r-25 {
        margin-right: 25px !important; }
      .sm-m-l-25 {
        margin-left: 25px !important; }
      .sm-m-b-25 {
        margin-bottom: 25px !important; }
      .sm-m-t-30 {
        margin-top: 30px !important; }
      .sm-m-r-30 {
        margin-right: 30px !important; }
      .sm-m-l-30 {
        margin-left: 30px !important; }
      .sm-m-b-30 {
        margin-bottom: 30px !important; }
      .sm-m-t-35 {
        margin-top: 35px !important; }
      .sm-m-r-35 {
        margin-right: 35px !important; }
      .sm-m-l-35 {
        margin-left: 35px !important; }
      .sm-m-b-35 {
        margin-bottom: 35px !important; }
      .sm-m-t-40 {
        margin-top: 40px !important; }
      .sm-m-r-40 {
        margin-right: 40px !important; }
      .sm-m-l-40 {
        margin-left: 40px !important; }
      .sm-m-b-40 {
        margin-bottom: 40px !important; }
      .sm-m-t-45 {
        margin-top: 45px !important; }
      .sm-m-r-45 {
        margin-right: 45px !important; }
      .sm-m-l-45 {
        margin-left: 45px !important; }
      .sm-m-b-45 {
        margin-bottom: 45px !important; }
      .sm-m-t-50 {
        margin-top: 50px !important; }
      .sm-m-r-50 {
        margin-right: 50px !important; }
      .sm-m-l-50 {
        margin-left: 50px !important; }
      .sm-m-b-50 {
        margin-bottom: 50px !important; }
      .sm-no-margin {
        margin: 0px; }
      .sm-no-padding {
        padding: 0px; }
      .sm-text-right {
        text-align: right !important; }
      .sm-text-left {
        text-align: left !important; }
      .sm-text-center {
        text-align: center !important; }
      .sm-pull-right {
        float: right !important; }
      .sm-pull-left {
        float: left !important; }
      .sm-pull-reset {
        float: none !important; }
      .sm-block {
        display: block; }
      }
      /* Border Helpers
      ------------------------------------
      */
      .b-a,
      .b-r,
      .b-l,
      .b-t,
      .b-b {
        border-style: solid;
        border-width: 0; }
        .b-b-blue {
          border-style: solid;
          border-width: 0; 
          border-color: #0052bb;
        }
      .b-r {
        border-right-width: 1px; }

      .b-l {
        border-left-width: 1px; }

      .b-t {
        border-top-width: 1px; }

      .b-b {
        border-bottom-width: 1px; }

      .b-a {
        border-width: 1px; }

      .b-dashed {
        border-style: dashed; }

      .b-dotted {
        border-style: dotted;
        border-color: #C5D9E8;
        /* border-image-source: url("../../assets/img/dots.svg"); */
        border-image-slice: 33% 33%;
        border-image-repeat: round; }

      .b-thick {
        border-width: 2px; }

      .b-transparent {
        border-color: rgba(0, 0, 0, 0.4); }

      .b-transparent-white {
        border-color: rgba(255, 255, 255, 0.2) !important; }

      .b-grey {
        border-color: #d6d8d9; }

      .b-white {
        border-color: #fff; }

      .b-primary {
        border-color: #0052bb; }

      .b-complete {
        border-color: #0052bb; }

      .b-success {
        border-color: #81C926; }

      .b-info {
        border-color: #3b4752; }

      .b-danger {
        border-color: #f55753; }

      .b-warning {
        border-color: #f8d053; }

      /* Border Radius
      ------------------------------------
      */
      .b-rad-sm {
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px; }

      .b-rad-md {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; }

      .b-rad-lg {
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px; }

      .no-border {
        border: none !important; }


        /* Line-heights
        ------------------------------------
        */
        .lh-normal {
          line-height: normal; }

        .lh-10 {
          line-height: 10px; }

        .lh-11 {
          line-height: 11px; }

        .lh-12 {
          line-height: 12px; }

        .lh-13 {
          line-height: 13px; }

        .lh-14 {
          line-height: 14px; }

        .lh-15 {
          line-height: 15px; }

        .lh-16 {
          line-height: 16px; }

.z-1000{
  z-index:1000
}

.z-10000{
  z-index:10000
}

/* legend:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  top: 50%;
  background-color: rgba(0,0,0,.1);
  z-index: 1;
} */

legend span {
  padding: 0 .5rem 0 0;
  background-color: #fff;
  display: inline-block;
  z-index: 2;
  position: relative;}

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}